import PDFViewer from 'mgr-pdf-viewer-react';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function ViewPdf() {

    const params = useParams();
    const url = params.url;

    React.useEffect(() => {

    }, [])

    return (
        <>
            {url ?
                <>
                    <PDFViewer
                        document={{
                            url: 'https://de8ncxim7llc.blob.core.windows.net/documents/' + url,
                        }} />
                </>
                :
                <>
                    Loading ...
                </>
            }
        </>
    )
};