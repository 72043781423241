import React from 'react';
import axios from "axios";
import Config from "../../Config";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from 'react-loading-skeleton';
import { InputGroup, FormControl, Card, Button, Col, Row, Table } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom';

var QRCode = require('qrcode.react');


export default function ListarCursos() {

  const [dataCursos, setDataCursos] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    // document.title = "myComply: Courses";
    axios.get(Config.url + 'cursos', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
      .then(response => {
        setLoading(false);
        console.log(response);
        setDataCursos(response.data.data);
      })
      .catch(error => {
        console.log(error);
      })

  }, [])


  const searchTodos = async (search) => {
    setLoading(true);
    await axios.get(Config.url + 'cursos/', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
      .then(response => {
        setLoading(false);
        console.log(response);
        setDataCursos(response.data);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      })

  }


  const searchByNombre = async (search) => {
    setLoading(true);
    setDisabled(true);
    if (search != '') {

      await axios.get(Config.url + 'cursos/?titulo_contains=' + search, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
        .then(response => {
          setLoading(false);
          setDisabled(false);
          console.log(response);
          setDataCursos(response.data);
        })
        .catch(error => {
          setLoading(false);
          setDisabled(false);
          console.log(error);
        })

    } else {
      setLoading(false);
      setDisabled(false);
      searchTodos();
    }



  }

  return (
    <>
      <ToastContainer />
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  All Courses
      </Col>
                <Col style={{ textAlign: 'right' }} xs={6} md={6} lg={6}>
                  <NavLink className="btn btn-primary" to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses/new">New Course</NavLink>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>

              
              {!loading
                ?
                <div className="row mt-4">
                  <div className="table-responsive">
                    <Table>
                      <thead>

                        <tr>
                          <th>Title</th>
                          
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dataCursos.map((data, index) => {

                            return (
                              
                                <tr key={index.toString()}>
                                                                  
                                  <td>{data.titulo}</td>
                                  
                                  <td>
                                    <a href={"/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses/edit/" + data.id} className="btn btn-xs btn-info btn-rounded">Edit</a>
                                    
                                  </td>
                                </tr>

                              );

                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-lg-12">
                    <Skeleton count={5} />
                  </div>
                </div>

              }

            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
}