import React from 'react';
import axios from "axios";
import Config from "../../Config";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from 'react-loading-skeleton';
import { InputGroup, FormControl, Card, Button, Col, Row, Table } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import PaginationComponent from '../../Components/Pagination';
var QRCode = require('qrcode.react');


export default function ListarCertificados() {

  const [dataCertificados, setDataCertificados] = React.useState([]);
  const [dataCertificadosFiltrados, setDataCertificadosFiltrados] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, SetPageNumber] = React.useState(1);
  const [searchValue, SetSearchValue] = React.useState(null);
  const [clearSearchValue, SetClearSearchValue] = React.useState(false);
  const [totalRecords, SetTotalRecords] = React.useState(0);
  const [paginationData, SetPaginationData] = React.useState(null);

  React.useEffect(() => {
    // document.title = "myComply: Certificates";
    onLoad();
  },[pageNumber, clearSearchValue])

  async function onLoad(){
    await axios(
      {
      method: 'get',
      url: `${Config.url}certificados`,
      params: {
        PageNumber: pageNumber,
        SearchValue: searchValue,
      },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      }
    )
      .then(response => {
        setLoading(false);
        console.log(response);
        setDataCertificados(response.data.data);
        SetPaginationData(response.data.filtro);
        SetTotalRecords(response.data.filtro.totalRecords);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      })
  }

  const paginate = async (page) => {
    SetPageNumber(page);
    //  getEstudiantes();
  };

  const handleSearch = async (valueTosearch) => {
    console.log(valueTosearch);
    if (valueTosearch != '') {
      SetSearchValue(valueTosearch);
    } else {
      SetSearchValue(null);
    }

  }

  const handleButtonSearch = () => onLoad();
  
  const handleClearInput = () => {
    SetClearSearchValue(!clearSearchValue);
    SetSearchValue("");
    //getEstudiantes();
  };


  const ConfirmDelete = (id) => {
    setLoading(true);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await axios.delete(Config.url + 'certificados/' + id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
              .then(response => {
                setLoading(false);
                console.log(response);
                toast.success('Certificate deleted successfully');
                //searchTodos();
                onLoad();
              })
              .catch(error => {
                setLoading(false);
                console.log(error);
              })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


  

  
  return (
    <>
      <ToastContainer />
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  All Certificates
      </Col>
                <Col style={{ textAlign: 'right' }} xs={6} md={6} lg={6}>
                  <NavLink className="btn btn-primary" to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/certificates/new">New Certificate</NavLink>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>

            <div className="col-lg-12">
                  <InputGroup className="mb-3">
                    <Button variant="outline-secondary" id="button-addon2" disabled={loading || !searchValue} onClick={() => handleClearInput()}>
                      Clear Input
                    </Button>
                    <FormControl
                      placeholder="Search"
                      aria-label="Search"
                      value={searchValue}
                      onChange={(e) => { handleSearch(e.target.value) }}
                    />
                    <Button variant="outline-secondary" id="button-addon2" disabled={loading || !searchValue} onClick={() => handleButtonSearch()}>
                      Search
                    </Button>
                  </InputGroup>

                </div>
              {!loading 
              ? 
              <>
              <div className="row">
                    <div className="col-lg-12">
                      <h5>Total Records: {totalRecords}</h5>
                    </div>
                  </div>
              <div className="row mt-4">
                <div className="table-responsive">
                  <Table>
                    <thead>

                      <tr>
                        
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Course</th>
                        {/* <th>Options</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dataCertificados.map((data, index) => {
                          
                          return (
                            
                              <tr key={index}>
                                <td>{data.estudiante.firstName} {data.estudiante.lastName}</td>
                                <td>{data.estudiante.phone}</td>
                                <td>{data.estudiante.email}</td>
                                <td>{data.curso.titulo}</td>
                                <td>
                                  <button type="button" onClick={()=>{ConfirmDelete(data.id)}} className="btn btn-xs btn-danger btn-rounded">Delete</button>
                                </td>
                              </tr>

                            );

                        })
                      }
                    </tbody>
                  </Table>
                  {paginationData && <PaginationComponent paginate={paginate} paginationData={paginationData} />}
                </div>
              </div>
              </>
              :
              <div className="row">
                <div className="col-lg-12">
                <Skeleton count={5}/>
                </div>
              </div>
              }

            </Card.Body>
            </Card>
            </Col>
            </Row>
      
    </>
  );
}