import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import validator from 'validator';
import '../../App.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/croppie.css';
import '../../assets/css/selectize.bootstrap4.css';
import '../../assets/css/site.css';
import '../../assets/css/sweetalert.css';
import '../../assets/css/translateelement.css';
import Config from "../../Config";

import logo from '../../assets/img/dob-logo-white.png';
import logoSmall from '../../assets/img/nyc_white@x2.png';
import outline_accessible_forward_white_48dp from '../../assets/img/outline_accessible_forward_white_48dp.png';
import logoPDF from '../../assets/img/PDF_32.png';
import upper from '../../assets/img/upper-header-divider.gif';

export default function VerRegistro(props) {
  const history = useHistory();
  const location = history;

  let url;
  let id = props.match.params.id;
  let secure = props.match.params.secure;
  let guid = props.match.params.guid;
  let result = validator.isUUID(id);

  const [showCollapsed, setShowCollapsed] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [urlUsed, setUrlUsed] = React.useState('');
  const [dataCertificates, setDataCertificates] = React.useState([]);

  React.useEffect(() => {
    getRegistro();
  }, [id, secure, guid]);

  async function getRegistro() {


    //console.log(result);
    if (secure && id) {
      url = Config.url + "estudiantes/newguid/" + id
    }
    if (secure && id && guid) {
      url = Config.url + "estudiantes/guid/" + id + '/' + guid
    }

    await axios
      .get(url)
      .then((response) => {

        if (response.data.dynamicId) {
          history.replace('/tap=e/' + secure + '/' + id + '/' + response.data.dynamicId);
          return;
        }
        setData(response.data);
        //consultar curso
        // document.title = "myComply: Employee information for " + response.data.FirstName + ' ' +response.data.LastName
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setUrlUsed('Ya no se puede usar la URL')
      });
  }

  const getPdfUrl = (pdf) => {

    https://de8ncxim7llc.blob.core.windows.net/documents/

    return pdf.replace('https://de8ncxim7llc.blob.core.windows.net/documents/', '')

  }


  console.log(props);
  return (
    <>

      <header>
        <div className="nycidm-header">
          <div className="upper-header-black">
            <div className="container-nycidm">
              <span className="upper-header-left">
                <a aria-label="NYC home page" href="https://www1.nyc.gov/"><img className="small-nyc-logo" alt src={logoSmall} /></a>
                <img className="vert-divide" alt src={upper} />
                <span className="upper-header-black-title" translate="no">
                  NYC DOB Training Connect
                </span>
              </span>
              <span className="upper-header-a">
                <a href="https://dob-trainingconnect.cityofnewyork.us/Saml/InitiateSingleSignOn">Log In</a>
              </span>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-md navbar-dark mb-3" style={{ backgroundColor: '#002878' }}>
          <div className="container">
            <a className="navbar-brand" aria-label="NYC Department of Buildings Training Connect home page" href="https://dob-trainingconnect.cityofnewyork.us/"><img className="sc-navbar-logo" alt src={logo} /></a>
            <div className="my-0 mr-3">
              <a className="d-block sc-dashboard-brand" aria-label="NYC Department of Buildings Training Connect home page" style={{ fontSize: '1.5rem', lineHeight: '1.5rem' }} translate="no" href="https://dob-trainingconnect.cityofnewyork.us/">
                TRAINING<br />CONNECT
              </a>
            </div>
            <button onClick={() => setShowCollapsed(!showCollapsed)} className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`collapse navbar-collapse ${showCollapsed ? 'show' : ''}`}>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link text-center" aria-label="NYC Department of Buildings home page" style={{ lineHeight: '1.0rem' }} href="https://www1.nyc.gov/site/buildings/index.page" target="_blank" rel="noopener noreferrer">NYCDOB<br />Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-center" href="https://www1.nyc.gov/site/buildings/safety/site-safety-training.page" target="_blank" rel="noopener noreferrer">Learn</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-center" style={{ lineHeight: '1.0rem' }} href="https://dob-trainingconnect.cityofnewyork.us/CourseProviders">Course<br />Providers</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-center" href="https://www1.nyc.gov/site/buildings/safety/sst-frequently-asked-questions.page" target="_blank" rel="noopener noreferrer">FAQ</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-center" aria-label="Language selection" href="https://dob-trainingconnect.cityofnewyork.us/tap?e=D9DF0AFE18A4E073A226B60B58CDBFB2&c=0E0FC5C9C85914B5&p=3DMH#languages"><i className="bi bi-translate" /></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-center" aria-label="Email link to this page" href="mailto:%20?subject=NYCDOB%20Training%20Connect%20Link&body=https%3a%2f%2fdob-trainingconnect.cityofnewyork.us%2ftap%3fe%3dD9DF0AFE18A4E073A226B60B58CDBFB2%26c%3d0E0FC5C9C85914B5%26p%3d3DMH" data-toggle="tooltip" data-placement="bottom" title="Share this page link using email.">
                    <i className="bi bi-share" />
                  </a>
                </li>
                <li className="nav-item">
                  <a role="link" aria-label="Copy link to this page" tabIndex={0} className="nav-link text-center" onclick="copyLink()" data-toggle="tooltip" data-placement="bottom" title="Copy this page link to clipboard.">
                    <i className="bi bi-link-45deg" />
                  </a>
                </li>
              </ul>
              <div className="my-2 text-center" style={{ color: 'rgba(255,255,255,.5)', lineHeight: '1.0rem' }}>
              </div>
              <div id="google_translate_element" className="d-none" />
            </div>
          </div>
        </nav>
      </header>
      {data &&
        <>
          <div className="container" style={{ flexGrow: 1 }}>
            <main role="main" className="pb-3">
              <div className="row p-2">
                <div className="col-12 col-lg-auto">
                  <img className="sc-header-photo mx-auto mx-md-0 d-block d-md-flex" src={data && data.foto} />
                </div>
                <div className="col-lg">
                  <div className="row justify-content-center justify-content-md-start">
                    <h2 className="text-center">
                      {data && data.firstName} {data && data.lastName} <span className="sc-lead" />
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-4 col-md-3 sc-field-name">
                          SST ID
                        </div>
                        <div className="col-4 col-md-3 sc-field-value sc-card-id">
                          {data.sstId}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-md-3 sc-field-name">
                          Eye Color
                        </div>
                        <div className="col-4 col-md-3 sc-field-value">
                          {data.eyeColor}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-md-3 sc-field-name">
                          Height
                        </div>
                        <div className="col-4 col-md-3 sc-field-value">
                          {data.height}
                        </div>
                        <div className="col-4 col-md-3 sc-field-value">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Temporary Card */}
              {data.typeCard == 'Temporary Card' &&
                <>
                  <div className="card text-center mt-4 p-2" style={{ backgroundColor: data.typeCard == 'Temporary Card' ? '#da2639' : '', color: 'white' }}>
                    <h4 style={{ color: 'white' }}>{data.typeCard}</h4>
                    <p>
                      Approved By:
                      {data.approvedBy}                <br />
                      Issued On: {new Date(data.fecha_Creacion).toLocaleDateString('en-En')}<br />
                      Expires On: {new Date(data.expireDate).toLocaleDateString('en-En')}
                    </p>
                  </div>
                </>
              }

              {/* Site Safety SST Card */}
              {data.typeCard == 'Site Safety SST Card' &&
                <>
                  <div className="card text-center mt-4 p-2" style={{ backgroundColor: data.typeCard == 'Site Safety SST Card' ? '#1E64AB' : '', color: 'white' }}>
                    <h4 style={{ color: 'white' }}>{data.typeCard}</h4>
                    <p>
                      Approved By:
                      {data.approvedBy}              <br />
                      Issued On: {new Date(data.fecha_Creacion).toLocaleDateString('en-En')}<br />
                      Expires On: {new Date(data.expireDate).toLocaleDateString('en-En')}
                    </p>
                  </div>
                </>
              }

              {/* Supervisor Card */}
              {data.typeCard == 'Supervisor Card' &&
                <>
                  <div className="card text-center mt-4 p-2" style={{ backgroundColor: data.typeCard == 'Supervisor Card' ? '#eac133' : '', color: 'white' }}>
                    <h4 style={{ color: 'white' }}>{data.typeCard}</h4>
                    <p>
                      Approved By:
                      {data.approvedBy}                <br />
                      Issued On: {new Date(data.fecha_Creacion).toLocaleDateString('en-En')}<br />
                      Expires On: {new Date(data.expireDate).toLocaleDateString('en-En')}
                    </p>
                  </div>
                </>
              }


              <div className="mt-4">
                <div className="row mb-2 align-content-center mx-0">
                  <h3>Training History</h3>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-sm table-sc">
                    <thead>
                      <tr>
                        <th>
                          Proof
                        </th>
                        <th>
                          Certification
                        </th>
                        <th>
                          Issued By
                        </th>
                        <th>
                          Verified By
                        </th>
                        <th>
                          Issued On
                        </th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.certificado.map((data, index) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {data.pdf == null
                                  ?
                                  <>
                                    <a href={data.foto}><img className="sc-table-logo" src={data && data.foto} alt="..." /></a>
                                  </>
                                  :
                                  <>
                                    <a href={'/0f29ddd4be2fe3e504e308f0e9827a39/view/' + getPdfUrl(data.pdf)} target={'_blank'}><img className="sc-table-logo" src={logoPDF} alt="..." /></a>
                                  </>
                                }

                              </td>
                              <td>
                                <span className="badge badge-success">Active</span>
                                {data.curso.titulo}
                              </td>
                              <td>
                                {data.verificadoPor} </td>
                              <td>
                                {data.verificadoPor}                 </td>
                              <td className="sc-md-nowrap">
                                {new Date(data.fechaEmision).toLocaleDateString('en-En')}
                              </td>
                              <td className="text-right sc-md-nowrap">
                              </td>
                            </tr>
                          </>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </>
      }
      {urlUsed != '' &&
        <>
          <div className="container" style={{ flexGrow: 1 }}>
            <main role="main" className="pb-3">
              <div className="container card border-danger p-4 mb-3 text-center" style={{ borderWidth: 2 }}>
                <h4 className="text-danger text-uppercase">TAP CARD AGAIN FOR AN UPDATED URL</h4>
                <strong className="text-danger">Click <a href="https://dob-trainingconnect.cityofnewyork.us/Home/RequestAssistance">here</a> to request assistance</strong>
              </div>
            </main>
          </div>

        </>
      }
      <footer aria-labelledby="nyc-footer" role="contentinfo" style={{ marginTop: 'auto' }}>
        <span id="nyc-footer" className="sr-only">NYC.gov footer</span>
        <div className="container footerwrap">
          <h2 className="sr-only">More on NYC.gov</h2>
          <ul className="footerLinks">
            <li><a href="https://www1.nyc.gov/nyc-resources/agencies.page">Directory of City Agencies</a></li>
            <li><a href="https://www1.nyc.gov/home/contact-us.page">Contact NYC Government</a></li>
            <li><a href="https://a127-ess.nyc.gov">City Employees</a></li>
            <li><a href="https://a858-nycnotify.nyc.gov/notifynyc/">Notify NYC</a></li>
            <li><a href="http://a856-citystore.nyc.gov">City Store</a></li>
            <li><a href="https://www1.nyc.gov/connect/social-media.page">Stay Connected</a></li>
            <li><a href="https://www1.nyc.gov/connect/applications.page">NYC Mobile Apps</a></li>
            <li><a href="https://www1.nyc.gov/nyc-resources/nyc-maps.page">Maps</a></li>
            <li><a href="https://www1.nyc.gov/nyc-resources/resident-toolkit.page">Residents Toolkit</a></li>
            <li><a href="https://www1.nyc.gov/nyc-resources/send-feedback.page">Feedback</a></li>
          </ul>
          <h2 className="sr-only">NYC.gov in Other Languages<br /></h2>
          <ul id="languages" className="footerLang">
            <li><a href="javascript:setLanguage('ar')" lang="ar" translate="no">العربية</a></li>
            <li><a href="javascript:setLanguage('bn')" lang="bn" translate="no">বাঙালি</a></li>
            <li><a href="javascript:setLanguage('zh-CN')" lang="zh" translate="no">中文</a></li>
            <li><a href="javascript:setLanguage('ht')" lang="ht" translate="no">Kreyòl Ayisyen</a></li>
            <li><a href="javascript:setLanguage('pl')" lang="pl" translate="no">Polski</a></li>
            <li><a href="javascript:setLanguage('es')" lang="es" translate="no">Español</a></li>
            <li><a href="javascript:setLanguage('ru')" lang="ru" translate="no">Pусский</a></li>
            <li><a href="javascript:setLanguage('fr')" lang="fr" translate="no">Français</a></li>
            <li><a href="javascript:setLanguage('ko')" lang="ko" translate="no">한국어</a></li>
            <li><a href="javascript:setLanguage('ur')" lang="ur" translate="no">اُردُو</a></li>
            <li><a href="javascript:setLanguage('yi')" lang="yi" translate="no">ייִדיש</a></li>
            <li><a href="javascript:setLanguage('it')" lang="it" translate="no">Italiano</a></li>
            <li><a href="javascript:setLanguage('en')" lang="en" translate="no">English</a></li>
          </ul>
          <div className="footerSearch"><a href="http://www.nyc.gov"><img src="https://www1.nyc.gov/assets/home/images/global/nyc_white@x2.png" alt="NYC home page" /></a></div>
          <div className="footerLegal">
            <div>© City of New York. 2022, All Rights Reserved,<br />NYC is a trademark and service mark of the City of New York.</div>
            <div><a href="https://www1.nyc.gov/home/privacy-policy.page">Privacy Policy.</a>&nbsp;&nbsp;<a href="https://www1.nyc.gov/home/terms-of-use.page">Terms of Use.</a> <a href="http://www.nyc.gov/digitalaccess"><span className="sr-only">Learn more about Digital Accessibility from the Mayor's Office for People with Disabilities.</span> <img className="sc-navbar-logo" alt src={outline_accessible_forward_white_48dp} /> </a></div>
          </div>
        </div>
      </footer>


    </>

  );
}
