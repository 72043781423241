import React from "react";
import axios from "axios";
import Config from "../../Config";
import { useHistory } from "react-router-dom";
import LogoOsha from "../../assets/img/logoosha.PNG";
import LogoCheck from "../../assets/img/comprobado.png";
import LogoCheck2 from "../../assets/img/comprobado2.png";
import falltrained from "../../assets/img/falltrained.jpeg";
import drug from "../../assets/img/drug.jpeg";
import makeit from "../../assets/img/makeit.PNG";
import loveny from "../../assets/img/loveny.PNG";
import logos from "../../assets/img/logos.png";
//import logooshatrainer from '../../assets/img/logoosha2.jpeg';
import logomycomply from "../../assets/img/logov2.svg";
import logooshatrainer from "../../assets/img/logov2.svg";
import '../../App.css';
import validator from 'validator';
import { Navbar, Container, Row, Col, Card, Button } from "react-bootstrap";

export default function VerDetallesRegistro(props) {
  const history = useHistory();
  const location = history;
  let idestudiante = props.match.params.idestudiante;
  let idcertificado = props.match.params.idcertificado;
  const [data, setData] = React.useState(null);
  const [dataCertificates, setDataCertificates] = React.useState([]);

  React.useEffect(() => {
    // document.title = "myComply: Employee information for "
    getRegistro();
  }, []);

  async function getRegistro() {

    let url;
    let result = validator.isUUID(idcertificado);
    console.log(result);
    if(result){
      url = Config.url + "certificados/guid/" + idcertificado
    }else{
      url = Config.url + "certificados/oldid/" + idcertificado
    }

    await axios
      .get(url)
      .then((response) => {
        setData(response.data);
        document.title = "myComply: Employee information for " + response.data.firstName + ' ' +response.data.lastName
        //consultar curso
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log(props);
  return (
    <>
      <Navbar bg="light">
        <Navbar.Brand>
        <Button href={"/badge/"+idestudiante+"/"} variant="light"><strong>BACK</strong></Button>
        </Navbar.Brand>
        <Navbar.Text>Training for {data && data.estudiante.firstName} {data && data.estudiante.lastName}</Navbar.Text>
      </Navbar>
      <Container>
        
        <br/>
        
        <Row>
          <Col xs={12}>
          <Card bg="light">
          <Card.Header>
          <Row>
                 <Col className="text-center">
                 <strong>{data && data.curso.titulo}</strong> <br/>
                  <span className="text-success">Verified</span>
                 </Col>
                  
                </Row>

          </Card.Header>
              <Card.Body>
                 <img className="placeholderCertificate" src={data && data.foto} style={{width: 300, margin: 'auto', display: 'block'}}/>
              </Card.Body>
                
                </Card>

          </Col>
        </Row>
        <br/>
        <Row>
          <Col xs={12}>
          <Card bg="light">
          <Card.Header>
          <Row>
                 <Col className="text-center">
                 <strong>Training Information</strong>
                 
                 </Col>
                  
                </Row>

          </Card.Header>
              <Card.Body>
                 <Row>
                   <Col xs={6}>
                    <strong>Issued On</strong>
                   </Col>
                   <Col xs={6} className="text-right">
                     {data && data.fechaEmision}
                   </Col>
                 </Row>
                
                 <Row>
                   <Col xs={6}>
                    <strong>Verified By</strong>
                   </Col>
                   <Col xs={6} className="text-right">
                     {data && data.verificadoPor}
                   </Col>
                 </Row>
                 <Row>
                   <Col xs={6}>
                    <strong>Training Hours</strong>
                   </Col>
                   <Col xs={6} className="text-right">
                     {data && data.horasEntrenamiento}
                   </Col>
                 </Row>
                 <Row>
                   <Col xs={6}>
                    <strong>Trainer Name</strong>
                   </Col>
                   <Col xs={6} className="text-right">
                     {data && data.entrenador}
                   </Col>
                 </Row>
                 <Row>
                   <Col xs={6}>
                    <strong>Trainer Id</strong>
                   </Col>
                   <Col xs={6} className="text-right">
                     {data && data.entrenador_ID}
                   </Col>
                 </Row>
                
                </Card.Body>
                
                </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}
