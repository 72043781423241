import { useRef, useState } from "react";

export default function ImageUploader({ defaultImage, setTmpPhoto, setFieldValue }) {
  const dropbox = useRef(null);
  const fileSelect = useRef(null);
  const [image, setImage] = useState(defaultImage);
  const [progress, setProgress] = useState(0);

  async function handleImageUpload() {
    if (fileSelect) {
      fileSelect.current.click();
    }
  }

  function handleFiles(files) {
    console.log("handleFiles");
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      uploadFile(files[i]);
    }
  }
  //CLOUDINARY_URL=cloudinary://738935444425391:2zIbRpuh9NXURcL44EYgOsuLnNg@de8ncxim7
  function uploadFile(file) {
    const url = `https://api.cloudinary.com/v1_1/de8ncxim7/image/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgress(Math.round((e.loaded * 100.0) / e.total));
      console.log(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText);

        setImage(response.secure_url);
        setTmpPhoto(response.secure_url);
        setFieldValue('foto', response.secure_url, false);
        console.log(response.secure_url);
      }
    };



    fd.append("file", file);
    fd.append("upload_preset", 'qfwejzwz');
    fd.append("cloud_name", 'de8ncxim7');
    xhr.send(fd);
  }





  return (
    <div ref={dropbox}>
      {image ? (
        <>

        </>
      ) : (
        <div
          className="card"

        >
          <form className="flex justify-center items-center h-full">
            {progress === 0 ? (
              <div className="text-gray-700 text-center">
                {/* <button
                  className="btn btn-info rounded block m-auto"
                  onClick={handleImageUpload}
                  type="button"
                >
                  Browse Image
                </button> */}
                <input
                  ref={fileSelect}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFiles(e.target.files)}
                />
              </div>
            ) : (
              <span className="text-gray-700">{progress}%</span>
            )}


          </form>
        </div>
      )}
    </div>
  );
}