import React from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import './App.css';
import AppRoute from './AppRoute';
import DashboardLayout from './Layout/Dashboard/DashboardLayout';
import DefaultLayout from './Layout/DefaultLayout';
import ListarCertificados from './Screens/Certificates/ListarCertificados';
import NuevoCertificado from './Screens/Certificates/NuevoCertificado';
import ListarCursos from './Screens/Courses/ListarCursos';
import NuevoCurso from './Screens/Courses/NuevoCurso';
import Login from './Screens/Login/login';
import VerDetallesRegistro from './Screens/Main/VerDetallesRegistro';
import VerRegistro from './Screens/Main/VerRegistro';
import ViewPdf from './Screens/Pdf';
import ListarEstudiantes from './Screens/Students/ListarEstudiantes';
import NuevoEstudiante from './Screens/Students/NuevoEstudiante';
import UnauthenticatedAppRoute from './UnauthenticatedAppRoute';
function App() {

  return (
    // <Routes appProps={{activa,setActiva}}/>
    <Router>
      <Switch>
        <AppRoute path='/' exact layout={DefaultLayout} component={() => (<>404</>)} />
        <Route path='/0f29ddd4be2fe3e504e308f0e9827a39/login' exact component={Login} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/logout' exact layout={DefaultLayout} component={() => { localStorage.clear(); window.location.replace('/login') }} />
        <UnauthenticatedAppRoute path='/badge/:id' exact component={VerRegistro} layout={DefaultLayout} />
        <UnauthenticatedAppRoute path='/tap=e/:secure/:id' exact component={VerRegistro} layout={DefaultLayout} />
        <UnauthenticatedAppRoute path='/tap=e/:secure/:id/:guid' exact component={VerRegistro} layout={DefaultLayout} />
        <UnauthenticatedAppRoute path='/badge/:idestudiante/:idcertificado' exact component={VerDetallesRegistro} layout={DefaultLayout} />
        {/* <UnauthenticatedAppRoute  path='/badge/:idestudiante/:idcertificado' exact component={VerDetallesRegistro} layout={DefaultLayout}/> */}
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/dashboard' exact component={VerRegistro} layout={DashboardLayout} />
        {/* Estudiantes */}
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/students' exact component={ListarEstudiantes} layout={DashboardLayout} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/students/new' exact component={NuevoEstudiante} layout={DashboardLayout} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/students/edit/:id' exact component={NuevoEstudiante} layout={DashboardLayout} />
        {/* Certificados */}
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/certificates' exact component={ListarCertificados} layout={DashboardLayout} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/certificates/new' exact component={NuevoCertificado} layout={DashboardLayout} />
        {/* Cursos */}
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses' exact component={ListarCursos} layout={DashboardLayout} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses/new' exact component={NuevoCurso} layout={DashboardLayout} />
        <AppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses/edit/:id' exact component={NuevoCurso} layout={DashboardLayout} />
        {/* Ver PDF */}
        <UnauthenticatedAppRoute path='/0f29ddd4be2fe3e504e308f0e9827a39/view/:url' exact component={ViewPdf} layout={DefaultLayout} />
        <AppRoute path='/*' exact layout={DefaultLayout} component={() => (<>404</>)} />

      </Switch>
    </Router>
  );
}

export default App;
