import React from "react";
import axios from "axios";
import Config from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withFormik } from 'formik';
import Compress from "react-image-file-resizer";
import { useHistory } from "react-router-dom";

const NuevoCurso = (props) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setValues,
        setFieldValue,
        resetForm
    } = props;

    let history = useHistory();
    const [tmp_photo,setTmpPhoto] = React.useState(null);
    const id  = props.match.params.id;
    

    console.log(id, 'mostrando id');

    React.useEffect(()=> {
    
        // document.title = "myComply: New Course "

        if(id){
            values.ID = id;
            axios.get(Config.url+'cursos/'+id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                const fields = ['titulo'];
                fields.forEach(field => setFieldValue(field, response.data[field], false));
                //setFieldValue('FirstName', 'Probando');
            })
            .catch(error => {
                console.log(error);
                toast.error('Error');
            })

        }

    },[]) 

    
    return (
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                {/* INICIO DEL FORMULARIO */}
                <input type="hidden" onChange={(e)=>{handleChange(e);}} onBlur={handleBlur} name="ID" value={values.ID || null}/>
                <div className="row layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                        <div className="statbox widget box box-shadow">
                            <div className="widget-header">
                                <div className="row">
                                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>{id ? 'EDIT' : 'NEW'} COURSE</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-content widget-content-area">
                                <div className="row">
                                    
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Tittle</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="titulo"
                                            value={values.titulo || ''}
                                            />
                                            {errors.titulo && touched.titulo && errors.titulo}
                                        </div>
                                    </div>                   
                                </div>
                                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                                        Submit
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default withFormik(
    {
        enableReinitialize: true,
        // default values
        mapPropsToValues: () => ({
            
        }),
        validate: values => {
            // validation
        },
        handleSubmit: async (values, {resetForm, setSubmitting }) => {

            console.log(values.ID);
            if(values.ID){
                //edit mode
                await axios.put(Config.url+'cursos/'+values.ID, values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    console.log(response);
                    toast.success('Edit Successfully');
                    setTimeout(()=>{
                        window.location.replace('/0f29ddd4be2fe3e504e308f0e9827a39/courses');
                    },3000)
                    
                    //resetForm();
    
                })
                .catch(error => {
                    console.log(error);
                    toast.error('Error.');
    
                })
            }else{
                //add mode
                await axios.post(Config.url+'cursos/', values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                toast.success('Successfully');
                setTimeout(()=>{
                    window.location.replace('/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses');
                },3000)
                resetForm();

            })
            .catch(error => {
                console.log(error);
                toast.error('Error.');

            })
            }
            


            // setTimeout(() => {

            //     alert(JSON.stringify(values, null, 2));

            //     setSubmitting(false);

            // }, 1000);

        },
    }
)(NuevoCurso);
