import React, { useRef } from "react";
import Config from "../Config";
export default function PdfUploader({ setFieldValue }) {
  const dropbox = useRef(null);
  const fileSelect = useRef(null);

  const [uploaded, setUploaded] = React.useState(null);
  const [urlFile, setUrlFile] = React.useState(null);

  async function handleImageUpload() {
    if (fileSelect) {
      fileSelect.current.click();
    }
  }

  function handleFiles(files) {
    console.log("handleFiles");
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      uploadFile(files[i]);
    }
  }
  //CLOUDINARY_URL=cloudinary://738935444425391:2zIbRpuh9NXURcL44EYgOsuLnNg@de8ncxim7
  function uploadFile(file) {
    const url = Config.url + "estudiantes/upload";
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('token'))

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setUploaded(true);
        const response = JSON.parse(xhr.responseText);
        setFieldValue('pdf', response.blob.uri, false);
        setUrlFile(response.blob.uri);
        console.log(response.blob.uri);
      }
    };

    fd.append("file", file);
    xhr.send(fd);
  }





  return (
    <div ref={dropbox}>
      <div
        className="card"

      >
        <form className="flex justify-center items-center h-full">
          {uploaded
            ?
            <>
              <div className="text-gray-700 text-center">
                <input
                  type="text"
                  className="form-control"
                  value={urlFile}
                />
              </div>
            </>
            :
            <>
              <div className="text-gray-700 text-center">
                {/* <button
                  className="btn btn-info rounded block m-auto"
                  onClick={handleImageUpload}
                  type="button"
                >
                  Browse Image
                </button> */}
                <input
                  ref={fileSelect}
                  type="file"
                  accept="pdf"
                  onChange={(e) => handleFiles(e.target.files)}
                />
              </div>
            </>
          }
        </form>
      </div>
    </div>
  );
}